import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout/index'

import '../assets/css/success.css'

export default function success() {
  return (
    <Layout>
      <div className="success-area container pt-6 mt-6 pb-6 mb-6">
        <h1 class="display-4 mt-6 mb-6 text-pink">Success!</h1>
        <p className="lead mb-6 pb-6 text-white">
          Thanks so much for taking the time to get in touch with me. I will get
          back to you as soon as I can. In the meantime feel free to browse the
          site and if you have any other questions please don't hesitate to get
          in contact again.
        </p>
        <hr />
        <Link className="text-pink pb-6" to="/">
          Go Back Home
        </Link>
      </div>
    </Layout>
  )
}
